import { Plan } from '@wix/ambassador-checkout-server/types';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import { EmptyStateType } from '../../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Optional, SlotsStatus } from '../../types/types';
import { getValidPurchasedPricingPlansForService } from '../pricingPlans/pricingPlans';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking } from '@wix/bookings-checkout-api';
import { extractQueryParamValues } from '../queryParams/queryParams';
import { Experiments, IWixAPI } from '@wix/yoshi-flow-editor';
import { DO_NOT_CARE_STAFF } from '../../constants/constants';
import {
  getUrlQueryParamValue,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import type { CalendarSelections } from '@wix/bookings-catalog-calendar-viewer-utils';
import { getLocalDateTimeStartOfDay } from '../dateAndTime/dateAndTime';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  getServicePricingPlanIds,
  isStaffMemberConnectedToService,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export function createInitialState({
  wixCodeApi,
  servicesInView,
  rescheduleBookingDetails,
  initialErrors,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
  servicesVariants,
  experiments,
  calendarSelections,
}: {
  servicesInView: Service[];
  wixCodeApi: IWixAPI;
  rescheduleBookingDetails?: Booking;
  initialErrors: EmptyStateType[];
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
  servicesVariants?: ServiceOptionsAndVariants[];
  experiments?: Experiments;
  calendarSelections?: CalendarSelections;
}): CalendarState {
  const locationFilterOptions = initializeLocationFilterOptions({
    wixCodeApi,
    calendarSelections,
  });
  const staffFilterOptions = initializeStaffMembersFilterOptions({
    wixCodeApi,
    servicesInView,
    experiments,
    calendarSelections,
  });
  const purchasedPricingPlans = initializePurchasedPricingPlans({
    servicesInView,
    allPurchasedPricingPlans,
    isPricingPlanInstalled,
    isUserLoggedIn,
  });
  const selectedDate = initializeSelectedDate(wixCodeApi);

  const serviceVariantsMap = createServicesVariantsMap(servicesVariants);

  return {
    calendarStatus: CalendarStatus.INITIALIZING,
    slotsStatus: SlotsStatus.LOADING,
    servicesInView,
    selectedDate,
    selectedTimezone: undefined,
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlotsAtSelectedTime: undefined,
    selectedRange: undefined,
    availableSlotsPerDay: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    rescheduleBookingDetails,
    dialog: undefined,
    filterOptions: {
      [FilterTypes.SERVICE]: [],
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
    },
    initialErrors,
    purchasedPricingPlans,
    isUserLoggedIn,
    selectedVariantsOptions: [],
    serviceVariantsMap,
  };
}

const createServicesVariantsMap = (
  serviceVariants?: ServiceOptionsAndVariants[],
) => {
  if (!serviceVariants) {
    return {};
  }

  return serviceVariants.reduce<record<string, ServiceOptionsAndVariants="">>(
    (acc, serviceVariant) => {
      acc[serviceVariant.serviceId!] = serviceVariant;
      return acc;
    },
    {},
  );
};

const initializeLocationFilterOptions = ({
  calendarSelections,
  wixCodeApi,
}: {
  calendarSelections?: CalendarSelections;
  wixCodeApi: IWixAPI;
}): string[] => {
  const preSelectedLocations = calendarSelections?.location
    ? [calendarSelections.location]
    : extractQueryParamValues({
        wixCodeApi,
        queryParam: BookingsQueryParams.LOCATION,
      });

  return preSelectedLocations.filter(
    (location) => location !== ReservedLocationIds.OTHER_LOCATIONS,
  );
};

const initializeStaffMembersFilterOptions = ({
  servicesInView,
  experiments,
  calendarSelections,
  wixCodeApi,
}: {
  servicesInView: Service[];
  wixCodeApi: IWixAPI;
  experiments?: Experiments;
  calendarSelections?: CalendarSelections;
}): string[] => {
  const staffFilterOptions: string[] = [];
  const { services } = calendarSelections || {};
  if (services?.length === 1 && services[0]?.resources?.[0]) {
    staffFilterOptions.push(services[0].resources[0]);
    return staffFilterOptions;
  }
  const resourcesIds = extractQueryParamValues({
    wixCodeApi,
    queryParam: BookingsQueryParams.RESOURCE,
  });
  if (resourcesIds && resourcesIds.includes(DO_NOT_CARE_STAFF)) {
    staffFilterOptions.push(DO_NOT_CARE_STAFF);
  } else {
    staffFilterOptions.push(
      ...initializeStaffMemberFilterOptionsFromQuery({
        wixCodeApi,
        servicesInView,
      }),
    );
  }
  return staffFilterOptions;
};
const initializeStaffMemberFilterOptionsFromQuery = ({
  servicesInView,
  wixCodeApi,
}: {
  wixCodeApi: IWixAPI;
  servicesInView: Service[];
}): string[] => {
  const staffFilterOptions: string[] = [];
  const resourcesIds = extractQueryParamValues({
    wixCodeApi,
    queryParam: BookingsQueryParams.RESOURCE,
  });
  if (resourcesIds.length) {
    resourcesIds.forEach((resourceId) => {
      const isStaffMemberConnectedToSomeService = servicesInView.some(
        (service) => isStaffMemberConnectedToService(service, resourceId),
      );
      if (isStaffMemberConnectedToSomeService) {
        staffFilterOptions.push(resourceId);
      }
    });
  }
  return staffFilterOptions;
};
const initializePurchasedPricingPlans = ({
  servicesInView,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
}: {
  servicesInView: Service[];
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
}): Plan[] => {
  let purchasedPricingPlans: Plan[] = [];
  let servicePricingPlanIds: string[] = [];

  if (isPricingPlanInstalled) {
    if (isUserLoggedIn) {
      servicePricingPlanIds =
        (servicesInView.length &&
          getServicePricingPlanIds(servicesInView[0])) ||
        [];
      purchasedPricingPlans = getValidPurchasedPricingPlansForService({
        servicePricingPlanIds,
        allPurchasedPricingPlans: allPurchasedPricingPlans || [],
      });
    }
  }
  return purchasedPricingPlans;
};

const initializeSelectedDate = (wixCodeApi: IWixAPI): Optional<string> => {
  const selectedDate = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.DATE,
  );
  if (selectedDate) {
    return getLocalDateTimeStartOfDay(new Date(selectedDate));
  }
};
</string></record<string,>