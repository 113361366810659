import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  createFilterViewModels,
  FilterViewModel,
  memoizedFiltersViewModel,
} from '../filterViewModel/filterViewModel';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { Optional, SubtitleSourceOptions } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';

export type HeaderViewModel = {
  title?: string;
  subtitle?: string;
  serviceTagLine?: string;
  customSubtitle?: string;
  filters: FilterViewModel[];
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
};

export const memoizedHeaderViewModel: MemoizedViewModalFactory<
  Optional<headerviewmodel>
> = {
  dependencies: {
    state: ['servicesInView'],
    settings: [
      'headerSubtitleVisibility',
      'headerSubtitleSource',
      'headerSubtitle',
      'headerFiltersVisibility',
      'headerTitle',
      'headerTitleVisibility',
      'headerTimezoneSelectionVisibility',
      'headerVisibility',
    ],
    subDependencies: [
      memoizedFiltersViewModel.dependencies,
      memoizedTimezoneSelectionViewModel.dependencies,
    ],
  },
  createViewModel: createHeaderViewModel,
};

export function createHeaderViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<headerviewmodel> {
  const {
    settings,
    settingsParams,
    getContent,
    preset,
    isDateAndTimeViewMode,
    experiments,
    t,
  } = context;
  const { servicesInView } = state;
  const isShowTitleInDateAndTimeViewModeEnabled = experiments.enabled(
    'specs.bookings.showTitlesInDateAndTimeViewMode',
  );

  const isCalendarDisplayPerBreakpointEnabled = experiments.enabled(
    'specs.bookings.calendarDisplayPerBreakpoint',
  );
  const isHeaderVisible =
    isCalendarDisplayPerBreakpointEnabled ||
    settings.get(settingsParams.headerVisibility);
  if (!isHeaderVisible) {
    return undefined;
  }
  const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);

  let title: string | undefined;
  const isTitleVisible =
    !isDateAndTimeViewMode &&
    (isCalendarDisplayPerBreakpointEnabled ||
      settings.get(settingsParams.headerTitleVisibility));

  if (isTitleVisible) {
    title = isCalendar
      ? servicesInView[0].name!
      : getContent({
          settingsParam: settingsParams.headerTitle,
          translationKey: 'app.settings.defaults.header-title',
        });
  }

  if (isShowTitleInDateAndTimeViewModeEnabled && isDateAndTimeViewMode) {
    title = t('app.date-and-time-view-mode.title');
  }

  let subtitle;
  let serviceTagLine;
  let customSubtitle;
  const isSubtitleVisible =
    (!isDateAndTimeViewMode || isShowTitleInDateAndTimeViewModeEnabled) &&
    (isCalendarDisplayPerBreakpointEnabled
      ? isCalendar
      : settings.get(settingsParams.headerSubtitleVisibility));

  if (isSubtitleVisible) {
    if (isCalendarDisplayPerBreakpointEnabled) {
      serviceTagLine = servicesInView[0].tagLine ?? undefined;
      customSubtitle = getContent({
        settingsParam: settingsParams.headerSubtitle,
        translationKey: 'app.settings.defaults.header-subtitle',
      });
    } else {
      if (
        !isDateAndTimeViewMode &&
        settings.get(settingsParams.headerSubtitleSource) ===
          SubtitleSourceOptions.SERVICE
      ) {
        subtitle = servicesInView[0].tagLine ?? undefined;
      } else {
        subtitle = getContent({
          settingsParam: settingsParams.headerSubtitle,
          translationKey: 'app.settings.defaults.header-subtitle',
        });
      }
    }
  }

  const filters = !isDateAndTimeViewMode
    ? createFilterViewModels({ state, context })
    : [];

  const isTimezoneSelectionVisible =
    !isCalendar &&
    (isCalendarDisplayPerBreakpointEnabled ||
      settings.get(settingsParams.headerTimezoneSelectionVisibility));

  const timezoneSelectionViewModel = isTimezoneSelectionVisible
    ? createTimezoneSelectionViewModel({
        state,
        context,
      })
    : undefined;

  const isAtLeastOneHeaderElementExists =
    !!title ||
    !!(isCalendarDisplayPerBreakpointEnabled
      ? serviceTagLine || customSubtitle
      : subtitle) ||
    filters.length ||
    !!timezoneSelectionViewModel;

  if (isAtLeastOneHeaderElementExists) {
    return {
      title,
      subtitle,
      serviceTagLine,
      customSubtitle,
      filters: filters!,
      timezoneSelectionViewModel,
    };
  }
}
</headerviewmodel></headerviewmodel>