import { Basket } from '@wix/bookings-services-preferences-modal';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import {
  AUTO_OPEN_PREFERENCES_MODAL_STORAGE_KEY,
  mapBasketToCalendarSelections,
  storeCalendarSelections,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export type OnServicesPreferencesConfirm = (basket: Basket) => void;

export const createOnServicesPreferencesConfirm =
  (
    { context }: ActionFactoryParams<calendarstate, CalendarContext="">,
    closeDialog: CloseDialogAction,
  ): OnServicesPreferencesConfirm =>
  (basket) => {
    const {
      reloadWidget,
      experiments,
      flowAPI: {
        controllerConfig: {
          platformAPIs: {
            storage: { session },
          },
        },
      },
    } = context;

    const calendarSelections = mapBasketToCalendarSelections(basket);
    storeCalendarSelections({ calendarSelections, session });
    experiments.enabled('specs.bookings.dynamicDurationUoU') &&
      session.removeItem(AUTO_OPEN_PREFERENCES_MODAL_STORAGE_KEY);

    closeDialog();
    reloadWidget();
  };
</calendarstate,>